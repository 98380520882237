<div class="accordion search-result-top-2em"
     id="accordionExample"
     *ngIf="resultedFluids.length > 0">

  <div class="alert alert-info" role="alert">
    <h1 class="m-0">Approved fluids</h1>

    Your fluid not listed? <br>
    Contact your fluid supplier or send an inquiry to Dana via the <a href="https://dana.fluidapproval.com/support/contact?selectedvalue=FluidNotListed" target="_blank" class="alert-link"><U>contact page</U></a>.
  </div>

  <div class="card" *ngFor="let fluids of resultedFluids; let i = index">
    <div class="card-header" [attr.id]="'headingOne' + i">
      <div data-toggle="collapse"
           [attr.data-target]="'#collapseOne' + i"
           aria-expanded="true"
           [attr.aria-controls]="'#collapseOne' + i"
           [ngClass]="{ collapsed: fluids.NumberOfApprovedFluids == 0 }">
        <table class="table table-borderless table-sm">
          <thead>
            <tr *ngIf="fluids.SpecificationName">
              <th class="headline-large">
                Specification : 
                <span style="font-weight: bold;">{{ fluids.SpecificationName }}</span>
              </th>
            </tr>
            <tr *ngIf="fluids.Version">
              <th class="headline-large">
                Version : 
                <span style="font-weight: bold;">{{ fluids.Version }}</span>
              </th>
            </tr>
            <tr *ngIf="fluids.SumpHeaterNote">
              <th class="headline-large">{{ fluids.SumpHeaterNote }}</th>
            </tr>
            <tr *ngIf="fluids.MinimumTemperature && !fluids.SumpHeaterNote">
              <th class="headline-large">
                Minimum Prevailing Ambient Temperature :
                <span style="font-weight: bold;">{{ fluids.MinimumTemperature }} &deg;C </span>
              </th>
            </tr>
             <!-- <tr *ngIf="fluids.SpecificationDescription">
              <th class="headline-large">
                Description : {{ fluids.SpecificationDescription }}
              </th>
            </tr>
            <tr *ngIf="fluids.AdditionalNotes">
              <th class="headline-large">
                Additional Notes: {{ fluids.AdditionalNotes }}
              </th>
            </tr> -->
          </thead>
        </table>
      </div>
    </div>

    <div [attr.id]="'collapseOne' + i"
         class="collapse borderbtm"
         [ngClass]="{ show: fluids.NumberOfApprovedFluids > 0 }"
         [attr.aria-labelledby]="'headingOne' + i">
            <div *ngIf="fluids.SpecificationDescription">
            <th class="headline-large">
             <span style="font-size: 14px;">Description : {{ fluids.SpecificationDescription }}</span>
            </th>
          </div>
          <div *ngIf="fluids.AdditionalNotes">
            <th class="headline-large">
             <span style="font-size: 14px;">Additional Notes: {{ fluids.AdditionalNotes }}</span>
            </th>
          </div>
      <div class="card-body" *ngIf="fluids.ApprovedFluids; else elseBlock">  
          <table class="table table-bordered table-sm table-striped" style="width: 100%;">
            <thead>
              <tr>
                <th scope="col" style="width: 33%;">Product</th>
                <th scope="col" style="width: 34%;">Company</th>
                <th scope="col" style="width: 33%;">Approval ID</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cus of fluids.ApprovedFluids">
                <td>{{ cus.customerProductName }}</td>
                <td>{{ cus.customerName }}</td>
                <td><a href="{{verifyUrl}}{{ cus.approvalId }}" target="_blank">
                  {{ cus.approvalName }}</a> </td>
              </tr>
            </tbody>
          </table>
      </div>
      <ng-template #elseBlock>
        <div class="card-body">
          <div class="alert-danger norecords">No Approved Fluids Found</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #nodataFound>
  <div class="search-result-top-2em">
    <div class="alert-danger norecords">No Records Found</div>
  </div>
</ng-template>
