//export const environment = {
//  production: true
//};

export const environment = {
  production: false,
  name: 'sbx',
  baseUrl: 'https://dana.api.sandbox.fluidselector.com/api/',
  baseUrlLocal: 'https://localhost:44328/api/',
  fmpUrl:'https://dana.sandbox.fluidapproval.com/',
  scopeUri: 'api://172e5481-d3a5-4ce1-aca6-683f6f5a9e0c/fluidselectorapp',
  tenantId: '4b4957de-1c49-418b-8107-8d7a8e08e4d6',
  uiClienId: 'de97e04a-2885-4670-80fa-3f742eb29012',
  redirectUri: 'https://dana.sandbox.fluidselector.com/admin',
  redirectUriLocal: 'http://localhost:4200/admin/',
  redirectAdminUri: 'admin/',
  postLogoutRedirectUri: "https://dana.sandbox.fluidselector.com/",
  postLogoutRedirectUriLocal: "http://localhost:4200/",
  authority: "https://login.microsoftonline.com/4b4957de-1c49-418b-8107-8d7a8e08e4d6",
  approvalVerifyUrl:"approval/verify?approvalId=",
  messageParams: {
    msgMarketAdd: "Market added  successfully",
    msgMarketUpdate: "Market Updated  successfully",
    msgMarketDeleted: "Market Deleted  successfully",
    msgModelAdd: "Model added  successfully",
    msgModelUpdate: "Model Updated  successfully",
    msgModelDeleted: "Model Deleted  successfully",
    msgModelFamilyAdd: "Model Family added  successfully",
    msgModelFamilyUpdate: "Model Family Updated  successfully",
    msgModelFamilyDeleted: "Model Family Deleted  successfully",
    msgMappingSuccess: "Mapped successfully",
    msgMappingDelete: "Mapping Deleted  successfully",
    msgUpdateError: "Not Updated",
    msgSaveError: "Not Saved ",
    msgDeleteError: "Not Deleted",
    msgMarketDeleteMappingExists: "Active mapping exists with the selected market. Please delete the mapping before deleting the Market.",
    msgModelDeleteMappingExists: "Active mapping exists with the selected model. Please delete the mapping before deleting the model.",
    msgModelFamilyDeleteMappingExists: "Active mapping exists with the selected model family. Please delete the mapping before deleting the model family.",
    msgMarketDuplicateError: "Market Already Exist",
    msgModelDuplicateError: "Model Already Exist",
    msgModelFamilyDuplicateError: "Model family Already Exist",
    msgModelSerialNumberMappingSuccess: "Model- SerialNumber Mapping added successfully",
    msgModelFamilyModelMappingSuccess: "Model Family - Model Mapping added successfully",
    msgModelFamilySpecificationMappingSuccess: "Model Family- Specification Mapping added successfully",
    msgModelFamilySpecificationMappingDuplicate: "Model Family- Specification details already exists",
    msgMarketModelFamilyMappingSuccess: "Market -Model Family Mapping added successfully",
    msgModelSerialNumberMappingDeleted: "Model- SerialNumber Mapping deleted successfully",
    msgModelFamilySpecificationMappingDeleted: "Model Family- Specification Mapping deleted successfully",
    msgMarketModelFamilyMappingDeleted: "Market -Model Family Mapping deleted successfully",
    msgModelFamilyMappingDeleted: "Model -Model Family Mapping deleted successfully",
    msgFileUploadSuccess: "File uploaded successfully",
    msgFileUploadFailure: "Some error occured when uploading the file.",
    msgInvalidFile: "Uploaded file is not valid.",
    msgNoFileUploaded: "Please select a file to upload.",
    msgInvalidName: "Please Enter valid name",
    msgMarketNameEmpty: "Market name should not be empty",
    msgModelFamilyEmpty: "Model Family name should not be empty",
    msgModelEmpty: "Model should not be empty",
    msgModelTagEmpty: "Model Tag  should not be empty",
    msgDuplicateError: "Not Saved--Duplicate exists",
    msgAccessDenied: "You are not authorized to access the Admin modules. Please contact your administrator.",
    msgNavigateToHome: "Click <a href='/'>here</a> to navigate to home page",
    msgSpecificationUpdate:"Model Family- Specification Mapping updated successfully",
    msgSpecificationDuplicateError:"Model Family- Specification Mapping already exists",
    msgDeleteConfirmationMasters: "Are you sure you want to delete [[Value]]?",
    msgDeleteConfirmationMapping: "Are you sure you want to delete the selected data?",
    msgDeleteConfirmationButton: "Yes, delete it!",
    msgModelSpecificationDelete:"Model Family- Specification Mapping deleted successfully",
    msgDeleteConfirmationModelSpecification: "Are you sure want to delete all the records?"


  },
  bulkuploadParams: {
    modelFamilyModelHeaderColumns: ['ModelTag', 'Model', 'ModelFamily', 'Description1', 'Description2'],
    modelSerialNumberHeaderColumns: ['SerialNumber', 'Model', 'ManufactureDate(MM/DD/YY)'],
    modelFamilySpecificationHeaderColumns: ['ModelFamily', 'Specification', 'MinimumTemperature','AdditionalNotes','Priority'],

  },
  constants: {
    Success: "Success",
    MappingExists: "Mapping Exists",
    Duplicate: "Duplicate",
    FSPAdminRole: ["FSPAdmin"],
    statusDropdownValues:['Active', 'Inactive']
  },
  bannerTexts: {
    marketTxt: "Market",
    modelText: "Model",
    modelFamilyText: "Model Family",
    MarketModelFamilyMappingText: "Market - Model Family Mapping",
    ModelFamilyModelMappingText: "Model - Model Family Mapping",
    ModelFamilySpecificationMappingText: "Model Family – Specification Mapping",
    ModelSerialNumberMappingText: "Model - Serial Number Mapping",
    fileUploadText: "File Upload",
    accessDenied: "Access Denied!",
  }
};
